import React from "react"
import { Column, Container, Row } from "../components/Grid"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import Resource from "../components/Resource"
import Section from "../components/Section"
import SEO from "../components/Seo"
import SubFooterImage from "../images/theme/img-subfooter-students.jpg"

const StudentsPage = () => (
  <Layout>
    <SEO title="Students" />
    {/***************  HERO ***************/}
    <Section className="hero">
      <Hero
        title="Students"
        infographic="img-hero-infographic-students.png"
        body="Explore interactive resources designed to empower students to make smart, healthy choices around substance misuse in the face of peer pressure."
        image="img-hero-students.jpg"
      />
    </Section>

    {/***************  RESOURCE HORIZONTAL ***************/}
    <Section className="resource-container midgray-background">
      <Column span={12}>
        <Resource
          label="Digital Exploration"
          image="thumbnail-digital-exploration@2x.jpg"
          subjects={["Student Module"]}
          title="Prescription Medication Misuse and the Adolescent Brain and Body"
          grade="Grades 6-12"
          duration="15 - 20 minutes"
          description={
            <p>
              In this interactive module, students will learn about the effects
              of prescription medication misuse on the developing adolescent
              brain and body and use this information to help them make informed
              and responsible decisions when confronted with peer pressure
              regarding the misuse of prescription medications.
            </p>
          }
          actions={[
            {
              label: "Get Started",
              path: "/prescription-medication-misuse",
              type: "module",
              moduleName: "Prescription Medication Misuse",
              locked: false,
            },
            // {
            //   label: "Educator Guide",
            //   path: "/docs/CVS-Pharma-DLB-Responding_to_Peer_Pressure-Pharmacists.pdf",
            //   type: "download",
            //   size: "PDF 1,000,000 GB",
            //   locked: false
            // }
          ]}
          horizontal
        />
      </Column>
    </Section>

    {/***************  ABOVE FOOTER CALLOUT ***************/}
    <Section className="subfooter">
      <Container>
        <Row>
          <Column span={4}>
            <img
              src={SubFooterImage}
              className="subfooter-image"
              alt="Title: Facts, Info etc."
            />
          </Column>
          <Column span={8}>
            <h3>Facts:</h3>
            <p>
              Because their bodies are still developing, particularly in the
              brain’s reward pathways, teens are especially vulnerable to
              high-risk and substance-seeking behaviors like opioid misuse.
            </p>
            <p>
              -
              <em>
                The Adolescent Brain, Annals of the New York Academy of Sciences
              </em>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
)
export default StudentsPage
